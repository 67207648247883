<template>
    <div>
        <v-checkbox                            
            label="อ่าน"            
            :input-value="$attrs.value >= 1"
            @change="$event ? $emit('input', 1) : $emit('input', 0)"
            :disabled="disabled"
        ></v-checkbox>
        <v-checkbox                            
            label="แก้ไข"                        
            :input-value="$attrs.value >= 2"
            @change="$event ? $emit('input', 2) : $emit('input', 1)"
            :disabled="disabled"
        ></v-checkbox>
        <v-checkbox                            
            label="ลบ"                        
            :input-value="$attrs.value >= 3"
            @change="$event ? $emit('input', 3) : $emit('input', 2)"
            :disabled="disabled"
        ></v-checkbox>
    </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>