<template>
    <div>
        <h2>{{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}เครื่อง POS</h2>
        <v-form v-model="isFormValid" autocomplete="off">          
          <v-card class="mt-4">
              <v-card-title>เครื่อง POS</v-card-title>

              <div class="pa-8">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.name"
                      outlined
                      label="ชื่อ"                               
                      :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                      hide-details="auto"          
                      dense  
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <delay-autocomplete
                      outlined
                      hide-selected
                      cache-items
                      v-model="formData.warehouse"
                      label="ผูกกับคลัง *"
                      placeholder="พิมพ์เพื่อค้นหาคลัง"
                      no-data-text="ไม่พบข้อมูล"
                      :url="'/possetting/device/warehouseList'"
                      dense
                      return-object
                      ref="deviceAutocomplete"
                      :lazySearch="true"
                      item-text="name"
                      item-value="id"
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      hide-details="auto" 
                      :rules="[validators.required]"
                    ></delay-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <delay-autocomplete
                      outlined
                      hide-selected
                      cache-items
                      v-model="formData.payments"
                      label="ช่องทางการชำระ"
                      placeholder="พิมพ์เพื่อค้นหาช่องทางการชำระ (เว้นว่างหากไม่เลือก)"
                      no-data-text="ไม่พบข้อมูล"
                      :url="'/possetting/device/pospaymentList'"
                      dense
                      return-object
                      ref="deviceAutocomplete"                      
                      item-text="name"
                      item-value="id"
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      hide-details="auto" 
                      multiple
                      chips
                      deletable-chips
                    ></delay-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    หัวบิล
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                      <v-text-field 
                        v-model="formData.branch"
                        label="สาขา *"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                        :rules="[validators.required]"
                      ></v-text-field>
                  </v-col>                    
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                      <v-text-field 
                        v-model="formData.address"                         
                        label="ที่อยู่ *"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                        :rules="[validators.required]"
                      ></v-text-field>
                  </v-col>                    
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                      <VThailandAddressAutoComplete 
                      hide-details="auto" 
                      dense v-model="formData.city" 
                      placeholder="แขวง/ตำบล *" 
                      name="city" 
                      type="district" 
                      @select="setAddress"
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      :rules="[validators.required]"
                      />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                      <VThailandAddressAutoComplete 
                      hide-details="auto" 
                      dense 
                      v-model="formData.county" 
                      placeholder="เขต/อำเภอ *" 
                      name="county" 
                      type="amphoe" 
                      @select="setAddress"
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      :rules="[validators.required]"
                      />
                  </v-col>                    
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                      <VThailandAddressAutoComplete 
                      hide-details="auto" 
                      dense 
                      v-model="formData.province" 
                      placeholder="จังหวัด *" 
                      name="province" 
                      type="province" 
                      @select="setAddress"
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      :rules="[validators.required]"
                      />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                      <VThailandAddressAutoComplete 
                      hide-details="auto" 
                      dense 
                      v-model="formData.postcode" 
                      placeholder="เลขไปรษณีย์ *" 
                      name="postcode" 
                      type="zipcode" 
                      @select="setAddress"
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      :rules="[validators.required]"
                      />
                  </v-col>                    
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                      <v-text-field 
                        v-model="formData.tel"                         
                        label="เบอร์ *"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                        :rules="[validators.required]"
                      ></v-text-field>
                  </v-col>                    
                </v-row>
                <v-row>
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="d-flex align-center">
                    <div>
                      ผู้มีสิทธิ์ใช้เครื่อง                      
                    </div>
                    <v-btn          
                    class="ml-6"           
                    fab
                    color="error"
                    small
                    @click="addNewAdmin"
                    >
                      <v-icon>{{ icons.mdiPlus }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-simple-table>
                      <thead>
                        <tr>
                          <td style="width: 50px"></td>
                          <td>
                            ชื่อแอดมิน
                          </td>
                          <td>สิทธิ์</td>
                        </tr>
                      </thead>
                      <tbody>        
                        <tr
                          v-for="(admin, index) in formData.admins"
                          :key="index"
                        >
                          <td>
                            <v-btn          
                            class="ml-6"           
                            color="error"
                            x-small       
                            @click="removeAdmin(index)"                     
                            >
                              <v-icon>{{ icons.mdiMinus }}</v-icon>
                            </v-btn>
                          </td>
                          <td>
                            <delay-autocomplete
                              outlined
                              hide-selected
                              cache-items
                              v-model="formData.admins[index]"
                              label="แอดมิน"
                              placeholder="พิมพ์เพื่อค้นหาแอดมิน"
                              no-data-text="ไม่พบข้อมูล"
                              :url="'/possetting/device/adminList'"
                              dense
                              return-object                              
                              :lazySearch="true"
                              item-text="name"
                              item-value="id"
                              :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                              hide-details="auto" 
                              :rules="[validators.required, validators.duplicateValidator(formData.admins[index], formData.admins, index, 'id')]"
                            ></delay-autocomplete>
                          </td>
                          <td>
                            <permission 
                            v-model="formData.permissions[index]"
                            :disabled="!formData.admins[index]"
                            ></permission>
                          </td>
                      </tr>                   
                      </tbody>
                    </v-simple-table>                                    
                  </v-col>
                </v-row>
              </div>

              <v-card-actions>              
                <v-btn
                  color="primary"               
                  large          
                  :disabled="!isFormValid"
                  :loading="sendingData"
                  @click="submitForm"
                  v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
                >
                  {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
                </v-btn>

                <v-btn
                  color="secondary"               
                  outlined                 
                  large
                  :disabled="sendingData"
                  @click="$router.go(-1)"
                >
                  กลับ
                </v-btn>                
              </v-card-actions>
          </v-card>
        </v-form>
    </div>
</template>
<script>
import { Device } from '@/classes/pos/device'
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import { convertPermissionToText } from '@/helpers/converter'
import { duplicateValidator, maxLengthValidator, required } from '@core/utils/validation'
import { mdiMinus, mdiPlus } from '@mdi/js'
import permission from './components/permission.vue'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      formData: new Device({
        branch: 'สำนักงานใหญ่',
        city: '',
        county: '',
        province: '',
        postcode: '',
      }),
      validators: {
        required,
        maxLengthValidator,
        duplicateValidator,
      },
      converters: {
        convertPermissionToText,
      },
      icons: {
        mdiPlus,
        mdiMinus,
      },
    }
  },
  async created() {
    const device_id = this.$route.params.device_id

    if (device_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'POSSetting'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/possetting/device/' + device_id)

      this.formData = new Device(response)
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        const sendData = this.formData.serialize()

        const device_id = this.$route.params.device_id

        if (device_id == 'create') await asyncPostAsJson('/possetting/device', sendData)
        else {
          sendData['_method'] = 'PUT'
          await asyncPostAsJson('/possetting/device/' + device_id, sendData)
        }

        this.$router.push({ name: 'device' })
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
    setAddress(address) {
      this.formData.city = address.district
      this.formData.county = address.amphoe
      this.formData.province = address.province
      this.formData.postcode = address.zipcode
    },
    addNewAdmin() {
      this.formData.admins.push(null)
      this.formData.permissions.push(1)
    },
    removeAdmin(index) {
      this.formData.admins.splice(index, 1)
      this.formData.permissions.splice(index, 1)
    },
  },
  components: {
    permission,
  },
}
</script>